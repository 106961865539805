@font-face {
    font-family: 'Kaneda Gothic';
    src: url('./fonts/KanedaGothic-Regular.otf') format('opentype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Kaneda Gothic';
    src: url('./fonts/KanedaGothic-Bold.otf') format('opentype');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Silka';
    src: url('./fonts/Silka-Regular.otf') format('opentype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Silka';
    src: url('./fonts/Silka-Bold.otf') format('opentype');
    font-weight: 700;
  }
  
  body {
    margin: 0;
    font-family: 'Silka', sans-serif;
    background-color: #FAF9F7;
    color: #000000;
  }
  
  .container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  .form-container {
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
  }
  
  .header {
    font-family: 'Kaneda Gothic', sans-serif;
    font-weight: 700;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .subheader {
    font-family: 'Kaneda Gothic', sans-serif;
    font-weight: 600;
    color: #66300F;
    margin-bottom: 30px;
  }
  
  .text {
    font-family: 'Silka', sans-serif;
    font-weight: 400;
    color: #000000;
  }
  
  .button-primary {
    background-color: #63C0C3;
    color: #FFFFFF;
    font-family: 'Silka', sans-serif;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    width: 100%;
    margin-top: 20px;
  }
  
  .button-primary:hover {
    background-color: #52b0b2;
  }
  
  .button-primary:active {
    background-color: #419fa1;
  }
  
  .progress-bar {
    height: 5px;
    background-color: #63C0C3;
    width: 0%;
    transition: width 0.3s;
  }
  
  .icon {
    margin-right: 8px;
  }
  
  .icon-text-field .MuiOutlinedInput-root {
    display: flex;
    align-items: center;
    padding-left: 8px;
  }
  
  .icon-text-field .MuiInputAdornment-root {
    margin-right: 8px;
  }
  
  .icon-text-field .MuiOutlinedInput-input {
    padding: 12px;
  }
  
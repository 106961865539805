body {
  margin: 0;
  font-family: 'Silka', sans-serif;
  background-color: #FAF9F7;
  color: #000000;
}

.card {
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.header {
  font-family: 'Kaneda Gothic', sans-serif;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
}

.subheader {
  font-family: 'Kaneda Gothic', sans-serif;
  font-weight: 600;
  color: #66300F;
  margin-bottom: 20px;
}

.text {
  font-family: 'Silka', sans-serif;
  font-weight: 400;
  color: #000000;
}

.button-primary {
  background-color: #63C0C3;
  color: #FFFFFF;
  font-family: 'Silka', sans-serif;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.button-primary:hover {
  background-color: #52b0b2;
}

.button-primary:active {
  background-color: #419fa1;
}

.button-secondary {
  background-color: #66300F;
  color: #FFFFFF;
  font-family: 'Silka', sans-serif;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.button-secondary:hover {
  background-color: #592a0d;
}

.button-secondary:active {
  background-color: #4c240b;
}

.container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.rating-container {
  margin-top: 20px;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feedback-form .MuiTextField-root {
  width: 100%;
}

.feedback-form .MuiButton-root {
  align-self: center;
}

.progress-bar {
  height: 5px;
  background-color: #63C0C3;
  width: 0%;
  transition: width 0.3s;
}

.icon {
  margin-right: 8px;
}

.icon-text-field .MuiOutlinedInput-root {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.icon-text-field .MuiInputAdornment-root {
  margin-right: 8px;
}

.icon-text-field .MuiOutlinedInput-input {
  padding: 12px;
}
